/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { styled } from '@hiyllo/ux/styled';
import { motion, useScroll, useTransform } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faComments, faEnvelope, faQuestionCircle, faSearch, faSitemap, faCalendar, faSquareKanban, faVideoCamera, faFolder, faRocket, faScissors, faUnlock, faGraduationCap, faSolarSystem } from '@fortawesome/pro-light-svg-icons';
import { Button } from '@hiyllo/ux/button';
import { LoadingSpinner } from '@hiyllo/ux/loading-spinner';
import moment from 'moment';
import { FakeChatWindow } from './fake-chat-window';
import { faArrowPointer } from '@fortawesome/pro-solid-svg-icons';
import { EffortlesslyConnected } from './effortlessly-connected';
import { ScrollApp } from './scroll-app';
import { VideoMeetingIcon } from '@hiyllo/icons/main';
import { ScrollFeatures } from './scroll-features';
import { ScrollNymbl } from './scroll-nymbl';
import { MobileLanding } from './mobile-landing';

const RootPage = styled('div', ({ $theme }) => ({
  overscrollBehavior: 'none',
  background: $theme.background1,
  color: $theme.foreground,
  // height: '100vh',
  width: '100vw',
  overflowX: 'hidden'
  // overflow: 'hidden',
  // scrollSnapType: 'y mandatory'
  // overflowY: 'auto',
}));

const IS_MOBILE = window.innerHeight > window.innerWidth;

const FullPageCentered = styled('div', {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  [IS_MOBILE ? 'minHeight' : 'height']: '100vh',
  width: '100vw',
  overflowX: 'hidden'
});

const ScreenshotImage = styled('img', ({ $theme }) => ({
  boxShadow: 'rgba(0,0,0,0.75) 0px 0px 50px -5px',
  borderRadius: 5
}));

const JumboText = styled('div', ({ $theme }) => ({
  fontSize: IS_MOBILE ? 42 : 48
  // marginBlock: 10
}));

const SnapSection = styled('div', {
  scrollSnapAlign: 'start'
});

const SplashPhotos = React.memo(function SplashPhotos(): JSX.Element {
  return (
    <div style={IS_MOBILE ? { paddingTop: 85 } : { position: 'relative' }}>
      <motion.div
        initial={{ opacity: 0, paddingTop: 200 }}
        animate={{ opacity: 1, paddingTop: 0 }}
        transition={{ duration: 2, delay: 1 }}
        style={IS_MOBILE ? { left: 0, width: '100vw', textAlign: 'center' } : { position: 'absolute', top: '-40%', width: '100%', textAlign: 'center' }}
      >
        <JumboText style={{ fontWeight: 'bold' }}>
            Everything your team needs <GradientText>in one place</GradientText>
        </JumboText>
        <div>
          <div style={{ display: 'inline-block', padding: 10, paddingLeft: 20, paddingRight: 20, borderRadius: 5, lineHeight: '1.25em' }}>
            <span style={{ opacity: 0.5 }}>Startups don&apos;t have time to juggle half a dozen apps,</span> Hiyllo Work let&apos;s you get to work.
          </div>
        </div>
        <div style={{ display: 'flex', flexDirection: 'row', gap: 10, alignItems: 'center', justifyContent: 'center' }}>
          <FontAwesomeIcon icon={faComments} fixedWidth/>
          <FontAwesomeIcon icon={faSquareKanban} fixedWidth/>
          <FontAwesomeIcon icon={faCalendar} fixedWidth/>
          <FontAwesomeIcon icon={faEnvelope} fixedWidth/>
          <FontAwesomeIcon icon={faFolder} fixedWidth/>
          <VideoMeetingIcon width={20} color="white"/>
        </div>
      </motion.div>
      <motion.div
        initial={{
          opacity: 0,
          transform: 'rotateY(40deg) rotateZ(10deg)'
        }}
        animate={{
          opacity: 1,
          transform: 'rotateY(0deg) rotateZ(0deg)'
        }}
        transition={{ duration: 1, delay: 0.2 }}
        style={IS_MOBILE ? { position: 'absolute', left: '10%', top: '55%' } : {}}
      >
        <ScreenshotImage src="/ss/chat-with-last-read.png" style={IS_MOBILE ? { width: '75vw', height: 'auto' } : { height: '100%', width: 'auto', maxHeight: '40vh', maxWidth: '40vw' }}/>
      </motion.div>
      <motion.div
        initial={{ opacity: 0, transform: 'rotateY(10deg) rotateZ(-2deg)' }}
        animate={{ opacity: 1, transform: 'rotateY(0deg) rotateZ(0deg)' }}
        transition={{ duration: 1, delay: 0.2 }}
        style={IS_MOBILE ? { position: 'absolute', top: IS_MOBILE ? 335 : '38%', left: '57.5%' } : { position: 'absolute', top: '70%', left: '80%' }}
      >
        <ScreenshotImage src="/ss/calendar.png" style={IS_MOBILE ? { height: '20vh', width: 'auto' } : { width: 'auto', height: '30vh' }}/>
      </motion.div>
      <motion.div
        initial={{ opacity: 0, transform: 'rotateY(10deg) rotateZ(-2deg)' }}
        animate={{ opacity: 1, transform: 'rotateY(0deg) rotateZ(0deg)' }}
        transition={{ duration: 1, delay: 0.2 }}
        style={IS_MOBILE ? { position: 'absolute', top: '70%', right: '50%' } : { position: 'absolute', top: '50%', right: '80%' }}
      >
        <ScreenshotImage src="/ss/kanban-project.png" style={{ width: 'auto', height: '27vh' }}/>
      </motion.div>
    </div>
  );
});

const ChatBubble = styled('div', ({ $theme }) => ({
  background: '#d0d0f8',
  color: '#333',
  padding: 15,
  borderRadius: 10,
  display: 'inline-block'
}));

const ImageLeftHalfCard = React.memo(function ImageHalfCard(props: {
  imageSrc: string;
  content: JSX.Element
}): JSX.Element {
  const ref = React.useRef(null);
  const { scrollYProgress } = useScroll({
    target: ref,
    offset: ['start end', 'end start']
  });
  const opacity = useTransform(scrollYProgress, [0, 0.5], [0, 1]);
  const imageTranslateX = useTransform(scrollYProgress, [0, 0.5], [-300, 0]);
  const contentTranslateX = useTransform(scrollYProgress, [0, 0.5], [300, 0]);
  const contentTranslateY = useTransform(scrollYProgress, [0, 0.5], [300, 0]);

  return (
    <div ref={ref} style={{ marginBottom: IS_MOBILE ? 80 : 0, overflow: 'hidden', gap: 40, height: IS_MOBILE ? '' : '100vh', display: 'flex', flexDirection: IS_MOBILE ? 'column' : 'row', width: '100%', alignItems: 'center', flexWrap: 'wrap' }}>
      <motion.div
        style={{
          boxShadow: 'rgba(0,0,0,0.75) 0px 0px 50px -5px',
          marginLeft: 40,
          borderRadius: 10,
          overflow: 'hidden',
          opacity,
          translateX: imageTranslateX
        }}
      >
        <img
          src={props.imageSrc}
          style={{
            width: IS_MOBILE ? '100vw' : '60vw',
            height: 'auto',
            borderRadius: 5
          }}
        />
      </motion.div>
      <motion.div style={{ width: IS_MOBILE ? '100%' : 0, flexGrow: 1, translateX: contentTranslateX, translateY: contentTranslateY }}>
        {props.content}
      </motion.div>
    </div>
  );
});

const ImageRightHalfCardActual = React.memo(function ImageHalfCard(props: {
  imageSrc: string;
  content: JSX.Element
}): JSX.Element {
  const ref = React.useRef(null);
  const { scrollYProgress } = useScroll({
    target: ref,
    offset: ['start end', 'end start']
  });
  const opacity = useTransform(scrollYProgress, [0, 0.5], [0, 1]);
  const imageTranslateX = useTransform(scrollYProgress, [0, 0.5], [300, 0]);
  const contentTranslateX = useTransform(scrollYProgress, [0, 0.5], [-300, 0]);
  const contentTranslateY = useTransform(scrollYProgress, [0, 0.5], [-300, 0]);

  return (
    <div ref={ref} style={{ overflow: 'hidden', gap: 40, height: IS_MOBILE ? '' : '100vh', display: 'flex', flexDirection: IS_MOBILE ? 'column' : 'row', width: '100%', alignItems: 'center' }}>
      <motion.div style={{ width: 0, flexGrow: 1, paddingLeft: 40, translateX: contentTranslateX, translateY: contentTranslateY }}>
        {props.content}
      </motion.div>
      <motion.div
        style={{
          boxShadow: 'rgba(0,0,0,0.75) 0px 0px 50px -5px',
          marginRight: 40,
          borderRadius: 10,
          overflow: 'hidden',
          opacity,
          translateX: imageTranslateX
        }}
      >
        <img
          src={props.imageSrc}
          style={{
            width: '60vw',
            height: 'auto',
            borderRadius: 5
          }}
        />
      </motion.div>
    </div>
  );
});

const ImageRightHalfCard = IS_MOBILE ? ImageLeftHalfCard : ImageRightHalfCardActual;

function App(): JSX.Element {
  const { scrollY } = useScroll({
    target: { current: window.document.body },
    offset: ['start end', 'end start'],
    container: { current: document.getElementById('root') }
  });
  const whiteIconOpacity = useTransform(scrollY, [0, window.innerHeight], [1, 0]);
  const colorIconOpacity = useTransform(scrollY, [0, window.innerHeight], [0, 1]);
  // if(new Date().valueOf() < LAUNCH) {
  //   return (
  //     <FullPageCentered>
  //       <Countdown/>
  //     </FullPageCentered>
  //   )
  // }

  // if(window.innerHeight > window.innerWidth) {
  //   return (
  //     <FullPageCentered style={{color:"white"}}>
  //       <div style={{textAlign:"center", display: "flex", flexDirection:"column", alignItems:"center"}}>
  //         <JumboText style={{fontSize: 32}}>Hey there! 👋</JumboText>
  //         <div style={{maxWidth:"75%"}}>
  //           <JumboText style={{fontSize: 16}}>Our mobile site isn't quite ready yet, sorry! We&apos;re still experimenting with the best way to show you our vision on a phone.</JumboText>
  //           <JumboText style={{fontSize: 16}}>Could you check us out on your desktop?</JumboText>
  //         </div>
  //       </div>
  //       <div style={{position:"absolute", bottom:20, left: 20}}>
  //         <img src="/work-logo.png" style={{height:40, width:"auto"}}/>
  //       </div>
  //     </FullPageCentered>
  //   )
  // }

  // if (IS_MOBILE) {
  //   return <MobileLanding/>;
  // }

  return (
    <RootPage id="rootpage">
      <script async src="https://www.googletagmanager.com/gtag/js?id=G-6KF3277HHE"></script>
      <script>{`
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());

        gtag('config', 'G-6KF3277HHE');
      `}</script>
      <motion.div
        style={{
          position: 'fixed',
          height: '100%',
          width: '100%',
          pointerEvents: 'none',
          top: 0,
          left: 0,
          background: 'black',
          zIndex: 1000,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
        initial={{ opacity: 1 }}
        animate={{ opacity: 0 }}
        transition={{ duration: 0.5, delay: 0.8 }}
      >
        <LoadingSpinner/>
      </motion.div>
      <div style={{ position: 'fixed', zIndex: 100, backdropFilter: 'blur(20px)', top: 0, left: 0, height: 90, width: IS_MOBILE ? 'calc(100% - 30px)' : 'calc(100% - 60px)', paddingLeft: 30, paddingRight: IS_MOBILE ? 0 : 30, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
        <div style={{ position: 'relative', width: IS_MOBILE ? 20 : 40, height: IS_MOBILE ? 20 : 40 }}>
          <motion.img src="https://cdn.hiyllo.net/logo/work/icon-white.png" style={{ height: IS_MOBILE ? 20 : 40, width: 'auto', opacity: whiteIconOpacity }}/>
          <motion.img src={IS_MOBILE ? 'https://cdn.hiyllo.net/logo/work/icon-gradient.png' : '/work-logo.png'} style={{ height: IS_MOBILE ? 20 : 40, width: 'auto', opacity: colorIconOpacity, position: 'absolute', top: 0, left: 0 }}/>
        </div>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 10 }}>
          <div onClick={() => {
            document.getElementById('rootpage')?.parentElement?.scrollTo(0, document.getElementById('rootpage')?.scrollHeight ?? 0);
          }} style={{ cursor: 'pointer', paddingLeft: 20, paddingRight: 20 }}>
            Pricing
          </div>
          <a href="mailto:sales@hiyllo.io" style={{ textDecoration: 'none' }}>
            <div style={{ color: 'white', textDecoration: 'none', paddingLeft: 20, paddingRight: 20, userSelect: 'none' }}>
              <FontAwesomeIcon icon={faEnvelope} fixedWidth/>&nbsp;<span style={{ textDecoration: 'none', userSelect: 'auto' }}>sales@hiyllo.io</span>
            </div>
          </a>
          {!IS_MOBILE ?
            <Button
              style={{ fontFamily: 'sans-serif', fontWeight: 'bold' }}
              label="Try it free for 3 weeks"
              onClick={() => {
                window.parent.postMessage('login', '*');
              }}
            />
          : null}
        </div>
      </div>
      {IS_MOBILE ?
        <div style={{ height: '100vh' }}>
          <SplashPhotos/>
        </div>
      :
        <SnapSection>
          <div style={{ height: '99vh', paddingTop: 70, paddingLeft: 25, display: 'flex', flexDirection: 'row' }}>
            <div style={{ height: '100%', width: '60vw' }}>
              <div style={{ fontSize: '17.5vh' }}>
                <b>
                  Everything your team needs...
                </b>
                {/* <b>
                  What your team uses every day:
                </b> */}
                <br/>
                <GradientText>
                  <b>
                    In one place.
                  </b>
                </GradientText>
                <div>
                  <div style={{ display: 'flex', flexDirection: 'row', gap: 10, alignItems: 'center', justifyContent: 'flex-start', fontSize: 40, marginTop: 15 }}>
                    <FontAwesomeIcon icon={faComments} fixedWidth/>
                    <FontAwesomeIcon icon={faSquareKanban} fixedWidth/>
                    <FontAwesomeIcon icon={faCalendar} fixedWidth/>
                    <FontAwesomeIcon icon={faEnvelope} fixedWidth/>
                    <FontAwesomeIcon icon={faFolder} fixedWidth/>
                    <VideoMeetingIcon width={40} color="white"/>
                  </div>
                </div>
              </div>
            </div>
            <div style={{ width: '40vw', position: 'relative' }}>
              <motion.div
                initial={{
                  opacity: 0,
                  transform: 'rotateY(40deg) rotateZ(10deg)'
                }}
                animate={{
                  opacity: 1,
                  transform: 'rotateY(0deg) rotateZ(0deg)'
                }}
                transition={{ duration: 1, delay: 1.2 }}
                style={{ position: 'absolute', left: '0%', top: '2.5%' }}
              >
                <FakeChatWindow/>
              </motion.div>
              <motion.div
                initial={{
                  opacity: 0,
                  transform: 'rotateY(40deg) rotateZ(10deg)'
                }}
                animate={{
                  opacity: 1,
                  transform: 'rotateY(0deg) rotateZ(0deg)'
                }}
                transition={{ duration: 1, delay: 1.2 }}
                style={{ position: 'absolute', left: '27.5%', top: '32.5%' }}
              >
                <ScreenshotImage src="/ss/chat-with-last-read.png" style={{ height: '30vh', width: 'auto', maxHeight: '40vh', maxWidth: '40vw' }}/>
              </motion.div>
              <motion.div
                initial={{ opacity: 0, transform: 'rotateY(10deg) rotateZ(-2deg)' }}
                animate={{ opacity: 1, transform: 'rotateY(0deg) rotateZ(0deg)' }}
                transition={{ duration: 1, delay: 1.3 }}
                style={{ position: 'absolute', top: '90%', left: '20%' }}
              >
                <ScreenshotImage src="/ss/calendar.png" style={IS_MOBILE ? { height: '20vh', width: 'auto' } : { width: 'auto', height: '30vh' }}/>
              </motion.div>
              <motion.div
                initial={{ opacity: 0, transform: 'rotateY(10deg) rotateZ(-2deg)' }}
                animate={{ opacity: 1, transform: 'rotateY(0deg) rotateZ(0deg)' }}
                transition={{ duration: 1, delay: 1.4 }}
                style={{ position: 'absolute', top: '62.5%', left: '-15%' }}
              >
                <ScreenshotImage src="/ss/kanban-project.png" style={{ width: 'auto', height: '30vh' }}/>
              </motion.div>
            </div>
          </div>
        </SnapSection>
      }
      {!IS_MOBILE ?
        <div style={{ fontSize: '17.5vh', padding: 20 }}>
          <b>
            Chat.<br/>
            <div style={{ height: 30 }}/>
            Project<br/>Management.<br/>
            <div style={{ height: 30 }}/>
            Calendar.<br/>
            <div style={{ height: 30 }}/>
            Email.<br/>
            <div style={{ height: 30 }}/>
            File Storage.<br/>
            <div style={{ height: 30 }}/>
            <GradientText>
              Hiyllo Work.
            </GradientText>
          </b>
        </div>
      : null}
      <EffortlesslyConnected/>
      {!IS_MOBILE ? <ScrollApp/> : null}
      <ScrollFeatures/>
      <ScrollNymbl/>
      <FullPageCentered>
        <div>
          <JumboText style={{ textAlign: 'center' }}>
            <GradientText style={{ display: 'inline-block' }}>
              <span>
                Not to mention:
              </span>
            </GradientText>
          </JumboText>
          <div style={{ height: 40 }}/>
          <div style={{ paddingBottom: IS_MOBILE ? 40 : 0, display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'stretch', gap: 20, flexWrap: 'wrap' }}>
            <Card style={{ width: 'min(300px, 60vw)' }}>
              <FontAwesomeIcon icon={faSearch} style={{ fontSize: 100, marginBottom: 10 }}/>
              <div style={{ lineHeight: '1.2em' }}>A single <GradientText>AI-backed</GradientText> lighting-fast search for everything across your platform for your entire organization</div>
            </Card>
            <Card style={{ width: 'min(300px, 60vw)' }}>
              <FontAwesomeIcon icon={faQuestionCircle} style={{ fontSize: 100, marginBottom: 10 }}/>
              <div style={{ lineHeight: '1.2em' }}>US-based support for you and all your users included, with a 6 hour SLA for urgent requests (1 hour for incidents and outages)</div>
            </Card>
        </div>
        </div>
      </FullPageCentered>
      <FullPageCentered style={{ flexDirection: 'column', background: 'rgba(255, 255, 255, 0.02)', position: 'relative' }}>
        <JumboText style={{ letterSpacing: 0.5 }}>Pricing</JumboText>
        <div style={{ height: 20 }}/>
        <div style={{ display: 'flex', flexDirection: 'row', gap: 30, flexWrap: 'wrap', alignItems: 'center', paddingLeft: IS_MOBILE ? 30 : 0, paddingRight: IS_MOBILE ? 30 : 0 }}>
          <div>
            <Card style={{ width: 'min(300px, 65vw)', userSelect: 'none' }}>
              <JumboText style={{ fontSize: IS_MOBILE ? 80 : 98 }}>
                <GradientText style={{ letterSpacing: 1, textAlign: 'center' }}>
                  $19.99
                </GradientText>
              </JumboText>
              <JumboText style={{ fontSize: 20 }}>per user / month</JumboText>
            </Card>
            <div style={{ height: 20 }}/>
            <Button
              style={{ width: 'calc(min(300px, 65vw) + 50px)', height: 40, fontSize: 19 }}
              label="Get Started with a 3 Week Free Trial"
              onClick={() => {
                window.parent.postMessage('login', '*');
              }}
            />
          </div>
          <div style={{ paddingTop: IS_MOBILE ? 0 : 0, paddingLeft: IS_MOBILE ? 10 : 0, fontSize: 15, userSelect: 'none', lineHeight: '17.5px', maxWidth: 400, display: 'flex', flexDirection: 'column', gap: 10, textAlign: 'left' }}>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 10 }}>
              <div style={{ flexShrink: 0 }}><FontAwesomeIcon icon={faSolarSystem} fixedWidth/></div>
              <div>One plan, all the features in the universe (and beyond!)</div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 10 }}>
              <div style={{ flexShrink: 0 }}><FontAwesomeIcon icon={faCalendar} fixedWidth/></div>
              <div>Billed monthly, no annual contract or lock-in, no minimums</div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 10 }}>
              <div style={{ flexShrink: 0 }}><FontAwesomeIcon icon={faFolder} fixedWidth/></div>
              <div>You get 25GB of pooled storage with every user. Need more? We keep it simple: just 5&cent;/gb/mo</div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 10 }}>
              <div style={{ flexShrink: 0 }}><FontAwesomeIcon icon={faUnlock} fixedWidth/></div>
              <div>Get 3 weeks free: no credit card needed, no commitment, no strings attached.</div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 10 }}>
              <div style={{ flexShrink: 0 }}><FontAwesomeIcon icon={faRocket} fixedWidth/></div>
              <div>Startups and non-profits are eligible for discounts of up to 25%, email <span style={{ userSelect: 'text' }}>sales@hiyllo.io</span> for more information.</div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 10 }}>
              <div style={{ flexShrink: 0 }}><FontAwesomeIcon icon={faGraduationCap} fixedWidth/></div>
              <div>Students get Hiyllo Work for free for any purpose, just sign up with a <b>.edu</b> email or contact <span style={{ userSelect: 'text' }}>students@hiyllo.io</span></div>
            </div>
          </div>
        </div>
        <div style={{ textAlign: 'center', padding: 20, bottom: 10, fontSize: 12, position: 'absolute' }}>
          <div>
            <img src="https://cdn.hiyllo.net/logo/hiyllo/icon-white.png" style={{ height: 17.5, width: 17.5, opacity: 0.75, paddingBottom: 10 }}/>
          </div>
          <div>&copy; Hiyllo, Inc 2024 - All Rights Reserved - <a style={{ color: 'white' }} target="_blank" href="https://www.hiyllo.work/work/legal/privacy-policy.pdf" rel="noreferrer">Privacy Policy</a></div>
        </div>
      </FullPageCentered>
    </RootPage>
  );
}
// @ts-expect-error zzz
export const GradientText = styled('span', ({ $theme }) => ({
  background: $theme.buttonBackground,
  backgroundClip: 'text',
  color: 'transparent',
  WebkitBackgroundClip: 'text'
}));

const Card = styled('div', ({ $theme }) => ({
  background: $theme.background2,
  padding: 20,
  paddingLeft: 40,
  paddingRight: 40,
  borderRadius: 10,
  boxShadow: 'rgba(0,0,0,0.75) 0px 0px 50px -5px',
  textAlign: 'center'
}));

export { App as Landing };
